export interface EnvironmentKeys {
  app_env: string;
  segment_api_key: string;
  google_maps_api_key: string;
  userpilot_api_key: string;
  react_app_dd_client_id: string;
  react_app_dd_app_id: string;
  hubspot_portal_id: string;
  hubspot_smb_id: string;
  hubspot_enterprise_id: string;
  hubspot_broker_id: string;
  access_token: string;
  backend: string;
  login: string;
  login_api: string;
  legacyLLPEndpoint: string;
  hubspot_form_api_url: string;
  deals_dashboard_service_url: string;
}
const getEnv = (): EnvironmentKeys => {
  const app_env = !!window && (window as any)['env'] ? (window as any).env.app_env : 'integration';
  const environment = app_env !== 'production' ? app_env + '.' : '';

  const defaultEnv: EnvironmentKeys = {
    app_env: app_env,
    segment_api_key: '',
    google_maps_api_key: 'AIzaSyC74heFmsrhRDMqWgmxaIQZzTpV3GYrrFU',
    userpilot_api_key: 'STG-NX-c13b0c77',
    react_app_dd_client_id: '',
    react_app_dd_app_id: '',
    // hubspot_portal_id: '46577276',
    // hubspot_smb_id: '85aeb751-fa44-41a8-afd2-9bd43473a72e',
    // hubspot_enterprise_id: 'eabfb19e-36fa-4024-9e59-ec7e2a947027',
    hubspot_portal_id: '7595645',
    hubspot_smb_id: '546d5b76-9c98-4baf-b1a0-947a1422ccd1',
    hubspot_enterprise_id: 'ce63a480-8fe1-4ebc-b085-8fb121661fe2',
    hubspot_broker_id: '8ed59aa8-e33e-476c-8343-79f4ae845d46',
    login: `https://login.${environment}theguarantors.com/`,
    login_api: `https://login-api.${environment}theguarantors.com/`,
    legacyLLPEndpoint: `https://${environment}theguarantors.com/v2/landlord`,
    backend: `https://landlord-portal-backend.${environment}theguarantors.com/api/`,
    hubspot_form_api_url: 'https://api.hsforms.com/submissions/v3/integration/submit/',
    access_token: process.env.ACCESS_TOKEN ?? '',
    deals_dashboard_service_url: `https://deals-dashboard.${environment}theguarantors.com`,
    // hubspot_form_api_url: 'http://localhost:5001/hubspot/', // WireMock
    // backend: 'http://localhost:5001/api/', // WireMock
  };

  if (!!window) {
    (window as any)['env'] = { ...defaultEnv, ...((window as any)['env'] || {}) };
  }
  return (window as any)['env'];
};

export default getEnv;
