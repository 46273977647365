import { apiUri } from '../../constants/Constants';
import { client } from '../axios/axiosClient';

export type FeatureFlagKeys =
  | 'test'
  | 'enable_company_phone_for_smb'
  | 'is_new_smb_portfolio_amount'
  | 'enable_old_size_of_portfolio'
  | 'enable_new_renter_invites_for_smb'
  | 'enable_new_deals_dashboard'
  | 'enable_new_renters_insurance_dashboard'
  | 'enable_footer_trust_center'
  | 'enable_footer_marketplace'
  | 'enable_footer_blog_rename'
  | 'remove_footer_contact_us'
  | 'enable_self_enrolled_1_month_SDR_limit';

export type FeatureFlags = Record<FeatureFlagKeys, boolean>;

export type featureFlagData = {
  data: FeatureFlags;
};

const getFeatureFlags = async () => {
  return await client.get(apiUri.getFeatureFlags).then((value: featureFlagData) => {
    return value.data;
  });
};

export default getFeatureFlags;
